import numeral from 'numeral'
import React from 'react'

const PoReportCard = (props) => {
    const {name,count,cyan,magenta,yellow,black,cleaningSolution,total} = props
  return (
    <div>
         <div className="p-5 w-full bg-white rounded-lg shadow-lg border border-gray-200">
                        <div className="flex flex-col items-center gap-3">
                            <div className="text-center">
                                <h2 className="text-xl font-semibold text-gray-800">
                                    {name}
                                </h2>
                                <p className="text-lg text-gray-500">
                                    {numeral(count).format('0,0')}
                                </p>
                            </div>
                            <div className="w-full">
                                <ul className="space-y-2 text-sm text-gray-600">
                                    <li className="flex justify-between items-center">
                                        <span className="font-medium">
                                            Cyan:
                                        </span>
                                        <span>{numeral(cyan).format('0,0')}</span>
                                    </li>
                                    <li className="flex justify-between items-center">
                                        <span className="font-medium">
                                            Magenta:
                                        </span>
                                        <span>{numeral(magenta).format('0,0')}</span>
                                    </li>
                                    <li className="flex justify-between items-center">
                                        <span className="font-medium">
                                        Yellow:
                                        </span>
                                        <span>{numeral(yellow).format('0,0')}</span>
                                    </li>
                                    <li className="flex justify-between items-center">
                                        <span className="font-medium">
                                        Black:
                                        </span>
                                        <span>{numeral(black).format('0,0')}</span>
                                    </li>
                                    <li className="flex justify-between items-center">
                                        <span className="font-medium">
                                        CleaningSolution:
                                        </span>
                                        <span>{numeral(cleaningSolution).format('0,0')}</span>
                                    </li>
                                    <li className="flex justify-between items-center">
                                        <span className="font-bold">
                                        Total:
                                        </span>
                                        <span className='font-bold'>{numeral(total).format('0,0')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
    </div>
  )
}

export default PoReportCard