export const GENDER = [
    { label: 'male', value: 'male' },
    { label: 'female', value: 'female' },
];
export const ROLES = [
    { label: 'Super Admin', value: 'sup_admin' },
    { label: 'Admin', value: 'admin' },
    { label: 'Local_Admin', value: 'local_admin' },
    { label: 'Executive', value: 'executive' },
];
export const VOLUME = [
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
];
export const FORM = [
    { label: 'Liquid', value: 'LIQ' },
    { label: 'Powder', value: 'POW' },
    { label: 'SaltFree', value: 'SF' },
    { label: 'Press Cake', value: 'PC' },
    { label: 'Granules', value: 'GR' },
    { label: 'Crystal', value: 'CR' },
];

export const TSHIRT_SIZE = [
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'XL', value: 'XL' },
    { label: 'Xxl', value: 'Xxl' },
    { label: 'XXxl', value: 'XXxl' },
];
export const FOOD_PREFERENCE = [
    { label: 'Veg', value: 'Veg' },
    { label: 'Non veg', value: 'Non veg' },
    { label: 'Jain', value: 'Jain' },
];
export const MUSIC_PREFERENCE = [
    { label: 'Rock', value: 'Rock' },
    { label: 'Jazz', value: 'Jazz' },
    { label: 'Bollywood party', value: 'Bollywood party' },
    { label: 'Sufi', value: 'Sufi' },
    { label: 'Ghazal', value: 'Ghazal' },
];
export const CREW_MEMBERS = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
];
export const TITLE = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
];
export const PRODUCT_TYPE = [
    {
        label: 'INK',
        value: 'INK',
    },
    {
        label: 'MACHINE',
        value: 'MACHINE',
    },
];

export const PURCHASE_ORDER_STATUS = [
    {
        label: 'Open',
        value: 'open',
    },
    {
        label: 'In Progress',
        value: 'inProgress',
    },
    {
        label: 'Dispatch',
        value: 'dispatch',
    },
    {
        label: 'Delivered',
        value: 'delivered',
    },
    {
        label: 'Canceled',
        value: 'canceled',
    },
];
export const SPAREPART_PO_STATUS = [
    {
        label: 'Open',
        value: 'open',
    },
    {
        label: 'Close',
        value: 'close',
    },
    
];
export const CATEGORY_OPTIONS = [
    {
        label : 'Machine',
        value : 'MACHINE'
    },
    {
        label : 'Ink',
        value : 'INK'
    },
    {
        label : 'Spare Part',
        value : 'sparePart'
    },
];

export const TERMS_CONDITIONS = [
    {
        value : 'MACHINE',
        notes:[
            'Machine Term & Conditions1',
            'Machine Term & Conditions2',
            'Machine Term & Conditions3',
        ]
    },
    {
        value : 'INK',
        notes:[
            'Ink Term & Conditions1',
            'Ink Term & Conditions2',
            'Ink Term & Conditions3',
        ]
    },
    {
        value : 'sparePart',
        notes:[
            'Spare part Term & Conditions1',
            'Spare part Term & Conditions2',
            'Spare part Term & Conditions3',
        ]
    },

]
