import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableWithHeadingAndSearch from '../components/Table/TableWithHeadingAndSearch';
import {
    fetchPurchaseOrders,
    getPurchaseOrders,
} from '../app/reducers/PurchaseOrder/purchaseOrderSlice';
import moment from 'moment';
import {
    fetchComplaints,
    getComplaints,
} from '../app/reducers/Complaints/complaintSlice';
import { authAxiosInstance } from '../utils/axiosConfig';
import PoReportCard from '../components/infrastructure/cards/PoReportCard';
import { getAuth } from '../app/reducers/Auth/authSlice';
import QueryString from 'qs';

const Dashboard = () => {
    const { user } = useSelector(getAuth)
    const { purchaseOrder, loading: poLoading } =
        useSelector(getPurchaseOrders);
    const { complaint, loading: compLoading } = useSelector(getComplaints);
    console.log('comp.s', complaint);
    const dispatch = useDispatch();
    const [Po_report, setPo_Report] = useState([]);
    const [complaints_report, setComplaints_Report] = useState({});
    const [Quotation, setQuotation_Report] = useState([]);

    useEffect(() => {
        dispatch(
            fetchPurchaseOrders({
                populate: true,
                createdAt: {
                    $gte: moment().startOf('day').valueOf(),
                    $lte: moment().endOf('day').valueOf(),
                },
            })
        );
        dispatch(
            fetchComplaints({
                populate: true,
                createdAt: {
                    $gte: moment().startOf('day').valueOf(),
                    $lte: moment().endOf('day').valueOf(),
                },
            })
        );
    }, []);

    const fetchReportsNumber = async () => {
        // try {
        //     const query = QueryString.stringify({
        //         createdAt:{
        //             $gte:moment().startOf("day").valueOf(),
        //             $lte:moment().endOf("day").valueOf()
        //         }
        //     });
        //     const res = await authAxiosInstance.get(
        //         `purchaseOrder/report?${query}`
        //     );
        // } catch (error) {}
        const res = await authAxiosInstance.get(`purchaseOrder/report`);
        if (res?.data?.data?.length) {
            const dataObj = res.data.data.reduce((acc, item) => {
                if (item?._id) {
                    acc[item._id] = {
                        count: item.count,
                        cyan: item.totalCyan,
                        magenta: item.totalMagenta,
                        yellow: item.totalYellow,
                        black: item.totalBlack,
                        cleaningSolution: item.totalCleaningSolution,
                        totalColor:
                            item.totalCyan +
                            item.totalMagenta +
                            item.totalYellow +
                            item.totalBlack +
                            item.totalCleaningSolution,
                    };
                }
                return acc;
            }, {});
            setPo_Report(dataObj);
        }
    };
    const fetchComplaintsReportsNumber = async () => {
        // try {
        //     const query = QueryString.stringify({
        //         createdAt:{
        //             $gte:moment().startOf("day").valueOf(),
        //             $lte:moment().endOf("day").valueOf()
        //         }
        //     });
        //     const res = await authAxiosInstance.get(
        //         `purchaseOrder/report?${query}`
        //     );
        // } catch (error) {}
        const res = await authAxiosInstance.get(`complaint/report`);
        if (res?.data?.data?.length) {
            const dataObj = res.data.data.reduce((acc, item) => {
                if (item?._id) {
                    acc[item._id] = item.count;
                }
                return acc;
            }, {});
            setComplaints_Report(dataObj);
        }
    };
    const fetchQuotationsReportsNumber = async () => {
        // try {
        //     const query = QueryString.stringify({
        //         createdAt:{
        //             $gte:moment().startOf("day").valueOf(),
        //             $lte:moment().endOf("day").valueOf()
        //         }
        //     });
        //     const res = await authAxiosInstance.get(
        //         `purchaseOrder/report?${query}`
        //     );
        // } catch (error) {}
        let query = {}
        if(!['sup_admin','admin'].includes(user.role)){

            query = { createdBy : user.username }
        }
        const string = QueryString.stringify(query)
        const res = await authAxiosInstance.get(`quotation/report?${string}`);
        // if (res?.data?.data?.length) {
        //     const dataObj = res.data.data.reduce((acc, item) => {
        //         if (item?._id) {
        //             acc[item._id] = item.count;
        //         }
        //         return acc;
        //     }, {});
        //     setQuotation_Report(dataObj);
        // }
        const count = res?.data?.data;
        setQuotation_Report(count);
    };
console.log("Quotation",Quotation)
    useEffect(() => {
        fetchReportsNumber();
        fetchComplaintsReportsNumber();
        fetchQuotationsReportsNumber();
    }, []);

    const poCol = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + purchaseOrder?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: (prop) => {
                const { data, row } = prop;
                return moment(row?.original?.createdAt).format(
                    'DD/MM/YYYY HH:mm'
                );
            },
        },
        {
            Header: 'ORD_Number',
            accessor: 'orderNumber',
        },
        {
            Header: 'Customer',
            accessor: 'customer.name',
        },
        {
            Header: 'Company',
            accessor: 'customer.companyData.name',
        },
        {
            Header: 'Product',
            accessor: 'product.name',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Cyan',
            accessor: 'cyan',
        },
        {
            Header: 'Magenta',
            accessor: 'magenta',
        },
        {
            Header: 'Black',
            accessor: 'black',
        },
        {
            Header: 'Yellow',
            accessor: 'yellow',
        },
        {
            Header: 'Cleaning Solution',
            accessor: 'cleaningSolution',
        },
        {
            Header: 'Customer Message',
            accessor: 'customerMessage',
        },
    ];

    const compCol = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + complaint?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: (prop) => {
                const { data, row } = prop;
                return moment(row?.original?.createdAt).format(
                    'DD/MM/YYYY HH:mm'
                );
            },
        },
        {
            Header: 'Photos',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.original?.photos?.map &&
                            row.original.photos.map((ele) => (
                                <a
                                    href={`${process.env.REACT_APP_S3_URL}${ele}`}
                                    target="_blank"
                                >
                                    <img
                                        src={`${process.env.REACT_APP_S3_URL}${ele}`}
                                    />
                                </a>
                            ))}
                    </div>
                );
            },
        },
        {
            Header: 'Videos',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.original?.videos?.map &&
                            row.original.videos.map((ele, i) => (
                                <a
                                    className="text-indigo-500 underline"
                                    href={`${process.env.REACT_APP_S3_URL}${ele}`}
                                    target="_blank"
                                >
                                    <p>Video {i + 1}</p>
                                </a>
                            ))}
                    </div>
                );
            },
        },
        {
            Header: 'Customer',
            accessor: 'customer.name',
        },
        {
            Header: 'Company',
            accessor: 'customer.companyData.name',
        },
        {
            Header: 'Machine',
            accessor: 'product.name',
        },
        {
            Header: 'Description',
            Cell: ({ row }) => {
                return (
                    <div className="w-[300px]">
                        <p className="text-wrap ">
                            {row?.original?.description}
                        </p>
                    </div>
                );
            },
        },
    ];

    const poData = useMemo(() => {
        if (purchaseOrder && purchaseOrder?.docs) return purchaseOrder?.docs;
        else return [];
    }, [purchaseOrder]);

    const compData = useMemo(() => {
        if (complaint && complaint?.docs) return complaint?.docs;
        else return [];
    }, [complaint]);

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Dashboard
                </h1>
            </div>
            {/* P.O report*/}
            <div>
                <div className="grid grid-cols-3 gap-2 mx-auto  my-4">
                    <PoReportCard
                        name={'P.O Open'}
                        count={Po_report?.open?.count}
                        cyan={Po_report?.open?.cyan}
                        magenta={Po_report?.open?.magenta}
                        yellow={Po_report?.open?.yellow}
                        black={Po_report?.open?.black}
                        cleaningSolution={Po_report?.open?.cleaningSolution}
                        total={Po_report?.open?.totalColor}
                    />
                    <PoReportCard
                        name={'P.O In Progress'}
                        count={Po_report?.inProgress?.count}
                        cyan={Po_report?.inProgress?.cyan}
                        magenta={Po_report?.inProgress?.magenta}
                        yellow={Po_report?.inProgress?.yellow}
                        black={Po_report?.inProgress?.black}
                        cleaningSolution={
                            Po_report?.inProgress?.cleaningSolution
                        }
                        total={Po_report?.inProgress?.totalColor}

                    />
                    <PoReportCard
                        name={'P.O Dispatched'}
                        count={Po_report?.dispatch?.count}
                        cyan={Po_report?.dispatch?.cyan}
                        magenta={Po_report?.dispatch?.magenta}
                        yellow={Po_report?.dispatch?.yellow}
                        black={Po_report?.dispatch?.black}
                        cleaningSolution={Po_report?.dispatch?.cleaningSolution}
                        total={Po_report?.dispatch?.totalColor}

                    />
                </div>
                <div className="grid grid-cols-3 gap-2  my-4">
                    <PoReportCard
                        name={'P.O Delivered'}
                        count={Po_report?.delivered?.count}
                        cyan={Po_report?.delivered?.cyan}
                        magenta={Po_report?.delivered?.magenta}
                        yellow={Po_report?.delivered?.yellow}
                        black={Po_report?.delivered?.black}
                        cleaningSolution={
                            Po_report?.delivered?.cleaningSolution
                        }
                        total={Po_report?.delivered?.totalColor}

                    />
                    <PoReportCard
                        name={'P.O Canceled'}
                        count={Po_report?.canceled?.count}
                        cyan={Po_report?.canceled?.cyan}
                        magenta={Po_report?.canceled?.magenta}
                        yellow={Po_report?.canceled?.yellow}
                        black={Po_report?.canceled?.black}
                        cleaningSolution={Po_report?.canceled?.cleaningSolution}
                        total={Po_report?.canceled?.totalColor}
                    />
                    <div className=" p-2 shadow-lg border rounded ">
                        <div className="">
                            <h2 className="text-center  my-1 font-bold">
                                Total Quotation
                            </h2>
                            <p className="text-center ">
                                {Quotation[0]?.count}{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* complaints report*/}
            <div>
                <div className="grid grid-cols-3 gap-2  my-4">
                    <div className=" p-2   shadow-lg border rounded">
                        <div className="">
                            <h2 className="text-center  my-1 font-bold">
                                Complaints Open
                            </h2>
                            <p className="text-center ">
                                {complaints_report['open']}{' '}
                            </p>
                        </div>
                    </div>
                    <div className=" p-2   shadow-lg border rounded">
                        <div className="">
                            <h2 className="text-center  my-1 font-bold">
                                Complaints In Progress
                            </h2>
                            <p className="text-center ">
                                {complaints_report['InProgress']}{' '}
                            </p>
                        </div>
                    </div>
                    <div className=" p-2   shadow-lg border rounded">
                        <div className="">
                            <h2 className="text-center  my-1 font-bold">
                                Complaints Closed
                            </h2>
                            <p className="text-center ">
                                {complaints_report['closed']}{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[355px] overflow-auto">
                <TableWithHeadingAndSearch
                    columns={poCol}
                    data={poData}
                    heading={"Today's P.O"}
                />
            </div>
            <div className="mt-8 h-[355px] overflow-auto">
                <TableWithHeadingAndSearch
                    columns={compCol}
                    data={compData}
                    heading={"Today's Complaints"}
                />
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
                <div className="flex flex-col md:flex-row md:-mr-px"></div>
            </div>
        </div>
    );
};

export default Dashboard;
