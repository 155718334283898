import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Copy, Edit, MessageSquare, Printer } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import {
    fetchQuotations,
    getQuotation,
} from '../../app/reducers/Quotation/quotationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import ViewProductsModal from './ViewProductsModal';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { toast } from 'react-toastify';

const ViewQuotation = () => {
    const { user } = useSelector(getAuth);
    console.log('user',user)
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [quotationProductsData, setQuotationProductsData] = useState([]);

    const { quotation, loading } = useSelector(getQuotation);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(['sup_admin', 'admin'].includes(user.role)){
            dispatch(
                fetchQuotations({ approvalStatus: 'approved', status: 'pending' })
            );
        } else { 
            dispatch(
                fetchQuotations({ approvalStatus: 'approved', status: 'pending', createdBy : user?.username })
            );
            
        }
    }, []);

    const columns = [
        {
            Header: 'SrNo',
            Cell: ({ row }) => {
                return row.index + quotation?.pagingCounter;
            },
        },
        {
            Header: 'Quotation No',
            accessor: 'ref_no',
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
        },
        {
            Header: 'Contact No',
            accessor: 'contactNumber',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Products',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsProductModalOpen(true);
                            setQuotationProductsData(row?.original?.products);
                        }}
                        className="text-blue-400 underline hover:text-blue-700 cursor-pointer"
                    >
                        View Products
                    </div>
                );
            },
        },
        {
            Header: 'Total Products Price',
            Cell: ({ row }) => {
                const totalProductPrice = row?.original?.products?.reduce(
                    (acc, crr) => {
                        acc += parseFloat(crr.totalPrice || 0);
                        return acc;
                    },
                    0
                );

                return totalProductPrice.toFixed(2);
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-3 cursor-pointer">
                        <Copy
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/quotation/duplicate-quotation/${row.original._id}`
                                );
                            }}
                        />
                        <Edit
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/quotation/edit-quotation/${row.original._id}`
                                );
                            }}
                            color="blue"
                        />
                        <Printer 
                            color="blue"
                            onClick={async(e)=>{
                                e.stopPropagation();
                                const response = await authAxiosInstance.post(
                                    `/quotation/quotationPdf`, row?.original
                                )
                                const printerWindow = window.open("","_blank");
                                printerWindow.document.write(response.data);
                            }}
                        />
                        <MessageSquare 
                            color='green'
                            onClick={async(e)=>{
                                e.stopPropagation();
                                const response = await authAxiosInstance.post(
                                    `/quotation/pdfViaMessage`, row?.original
                                )
                                console.log('resp', response)
                                if(response?.data?.success){
                                    toast.success(`${response?.data?.message}`)
                                }
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const colsMemo = useMemo(() => columns ?? [], [columns]);
    const dataMemo = useMemo(
        () => (quotation?.docs ? quotation.docs : []),
        [quotation]
    );
    return (
        <PageWithCard heading="View Quotation">
            <ViewProductsModal
                openModal={isProductModalOpen}
                setOpenModal={setIsProductModalOpen}
                products={quotationProductsData}
            />
            {}
            <TableWithHeadingAndSearch
                columns={colsMemo}
                data={dataMemo}
                heading={loading ? <ClipLoader /> : 'View Quotation'}
            />
        </PageWithCard>
    );
};

export default ViewQuotation;
