import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import FormikFileInput from '../../components/formik/FormikFileInput';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import {
    createEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import { generateOptions } from '../../utils/Utils';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { ROLES } from '../../utils/dropdownOptions';
import {
    fetchDispatchLocations,
    getDispatchLocation,
} from '../../app/reducers/DispatchLocation/dispatchLocationSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddEmployee = () => {
    const { user } = useSelector(getAuth);
    const dispatch = useDispatch();

    const { dispatchLocation } = useSelector(getDispatchLocation);

    useEffect(() => {
        dispatch(fetchDispatchLocations());
    }, []);

    const dispatchLocationData = useMemo(
        () => (dispatchLocation.docs ? dispatchLocation.docs : []),
        [dispatchLocation.docs]
    );

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '91',
            password: '',
            role: '',
            location: '',
            status: true,
        },
        validationSchema: object({
            firstName: string().required(),
            lastName: string().required(),
            username: string().required(),
            password: string().required(),
            location: string().required(),
            role: string().required(),
        }),
        onSubmit: async (values,{resetForm}) => {
            dispatch(createEmployee(values));
            resetForm();
        },
    });
    return (
        <PageWithCard>
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4"
            >
                <FormikInputGroup
                    name="firstName"
                    label="First Name"
                    formik={formik}
                    required
                />
                <FormikInputGroup
                    name="lastName"
                    label="Last Name"
                    formik={formik}
                    required
                />

                <FormikPhoneInputGroup
                    name="username"
                    label="Phone Number"
                    formik={formik}
                    required
                />
                <FormikSelectGroup
                    label="Location"
                    name="location"
                    formik={formik}
                    options={generateOptions({
                        array: dispatchLocationData ?? [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <FormikSelectGroup
                    label="Role"
                    name="role"
                    formik={formik}
                    options={generateOptions({
                        array:
                            user?.role === 'sup_admin'
                                ? ROLES
                                : ROLES?.filter(
                                      (el) => el.value !== 'sup_admin'
                                  ),
                        labelField: 'label',
                        valueField: 'value',
                    })}
                />
                <FormikInputGroup
                    name="password"
                    type="password"
                    label="Password"
                    formik={formik}
                    required
                />

                <FormikCheckbox name="status" label="Status" formik={formik} />

                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default AddEmployee;
