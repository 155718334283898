import React from 'react'
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic'
import { useFormik } from 'formik';
import { SPAREPART_PO_STATUS } from '../../utils/dropdownOptions';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { editSparePartPurchaseOrder, fetchSparePartPurchaseOrders } from '../../app/reducers/PurchaseOrder/sparePartPurchaseOrderSlice';
import { useDispatch } from 'react-redux';

const SparePartPOStatusModal = ({
  isStatusModalOpen,
  setIsStatusModalOpen,
  sparePartPOStatusData,
  page,
  currentStatus,
}) => {
   const currentStatusIndex =  SPAREPART_PO_STATUS.findIndex(
          (item) => item.value == sparePartPOStatusData.status
      );
      const dispatch = useDispatch()
  const nextStatus =
          SPAREPART_PO_STATUS[
              currentStatusIndex != SPAREPART_PO_STATUS.length - 1
                  ? currentStatusIndex + 1
                  : currentStatusIndex
          ].value;
      const statusFormik = useFormik({
          enableReinitialize: true,
          initialValues: { ...sparePartPOStatusData, status: nextStatus ?? '' },
          onSubmit: async (values) => {
              await dispatch(editSparePartPurchaseOrder(values));
              await dispatch(
                fetchSparePartPurchaseOrders({
                      status: currentStatus,
                      populate: true,
                      page,
                  })
              );
              statusFormik.resetForm();
              setIsStatusModalOpen(false);
          },
      });
  return (
    <ModalBasic
    setModalOpen={setIsStatusModalOpen}
    modalOpen={isStatusModalOpen}
    title={'Update Status'}
    >
        <form
                onSubmit={statusFormik.handleSubmit}
                className="flex flex-col gap-2 p-3"
            >
                <FormikSelectGroup
                    formik={statusFormik}
                    name={'status'}
                    label="Status"
                    options={SPAREPART_PO_STATUS}
                />
                <FormikTextareaGroup
                    formik={statusFormik}
                    name={'notes'}
                    label="Message"
                    // readOnly={statusFormik.values.status !== 'dispatch'}
                />
                <div className="mb-2">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
    </ModalBasic>
  )
}

export default SparePartPOStatusModal