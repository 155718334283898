import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    createCompany,
    deleteCompany,
    fetchCompanies,
    getCompanies,
} from '../../app/reducers/Company/companySlice';
import { Edit2, Trash } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import ProductContainer from '../Users/ProductContainer';
import { fetchCustomers } from '../../app/reducers/Customers/customerSlice';
import * as Yup from 'yup';
import {
    fetchEmployees,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { exportCsv, generateOptions } from '../../utils/Utils';
import { ClipLoader } from 'react-spinners';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import {
    fetchDispatchLocation,
    fetchDispatchLocations,
    getDispatchLocation,
} from '../../app/reducers/DispatchLocation/dispatchLocationSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import moment from 'moment/moment';

const Company = () => {
    const { user } = useSelector(getAuth);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const { dispatchLocation } = useSelector(getDispatchLocation);
    const { employees, loading } = useSelector(getEmployees);
    const { company } = useSelector(getCompanies);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const payload = {
            limit: 500,
            page,
            populate: true,
            approvedStatus: 'approved',
        };
        if (user.role === 'local_admin') {
            payload['dispatchLocation._id'] = user.location;
        }
        dispatch(fetchCompanies(payload));
        dispatch(fetchEmployees({ role: 'executive' }));
        dispatch(fetchDispatchLocations());
    }, [page]);
    const formik = useFormik({
        initialValues: {
            name: '',
            machine: [],
            dispatchLocation: '',
            ink: [],
            executive: [],
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            dispatchLocation: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            console.log('values', values);
            if (user.role === 'local_admin') {
                await dispatch(createCompany(values));
            }
            if (['sup_admin', 'admin'].includes(user.role)) {
                const resp = await dispatch(
                    createCompany({ ...values, approvedStatus: 'approved' })
                );
                if (resp?.payload?.success) {
                    const res = await authAxiosInstance.post(
                        '/company/sendAdminMessage',
                        values
                    );
                    if (res?.data?.success) {
                        toast.success(`${res?.data?.message}`);
                    }
                }
            }
            const payload = {
                limit: 500,
                page,
                populate: true,
                approvedStatus: 'approved',
            };
            if (user.role === 'local_admin') {
                payload['dispatchLocation._id'] = user.location;
            }
            dispatch(fetchCompanies(payload));

            formik.resetForm();
        },
    });

    const cols = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: 'Company Name',
            accessor: 'name',
        },
        {
            Header: 'Executive',
            Cell: ({ row }) => {
                return (
                    <ul>
                        {row?.original?.executive?.map((exe) => (
                            <li>
                                {exe.firstName} {exe.lastName}
                            </li>
                        ))}
                    </ul>
                );
            },
        },
        {
            Header: 'Dispatch Location',
            Cell: ({ row }) => {
                return <div>{row?.original?.dispatchLocation?.name}</div>;
            },
        },
        {
            Header: 'Machine',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.machine);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Machine
                    </div>
                );
            },
        },
        {
            Header: 'INK',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.ink);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Ink
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-3">
                        <Edit2
                            onClick={() => {
                                navigate(
                                    `/master/company/edit/${row.original._id}`
                                );
                            }}
                        />
                        <Trash
                            onClick={async () => {
                                const customerResp = await dispatch(
                                    fetchCustomers({
                                        company: row.original._id,
                                    })
                                );

                                if (
                                    customerResp?.payload?.data?.docs?.length >
                                    0
                                ) {
                                    return toast.error(
                                        'You cannot delete this company'
                                    );
                                } else {
                                    await dispatch(
                                        deleteCompany({ id: row.original._id })
                                    );

                                    const payload = {
                                        limit: 500,
                                        page,
                                        populate: true,
                                        approvedStatus: 'approved',
                                    };
                                    if (user.role === 'local_admin') {
                                        payload['dispatchLocation._id'] =
                                            user.location;
                                    }
                                    dispatch(fetchCompanies(payload));
                                }
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const dispatchLocationData = useMemo(
        () => (dispatchLocation.docs ? dispatchLocation.docs : []),
        [dispatchLocation.docs]
    );

    const companyExportToCsv = async (e) => {
        e.stopPropagation();
        try {
            setIsDownloading(true);
            const payload = {
                limit: 500,
                populate: true,
                approvedStatus: 'approved',
            };
            if (user.role === 'local_admin') {
                payload['dispatchLocation._id'] = user.location;
            }

            const string = QueryString.stringify(payload);
            const resp = await authAxiosInstance.get(`/company?${string}`);

            const companies = resp?.data?.data?.docs;
            if (companies.length > 0) {
                let mainData = companies?.map((ele, i) => {
                    console.log('ele', ele);
                    let {
                        name,
                        machine,
                        ink,
                        executive,
                        dispatchLocation,
                        createdAt,
                    } = ele;
                    return {
                        srno: `${i + 1}`,
                        companyName: name,
                        executiveName: executive
                            ?.map((ele) => ele.firstName + ele.lastName)
                            .join(', '),
                        dispatchLocation: dispatchLocation?.name,
                        machineName: machine?.map((ele) => ele.name).join(', '),
                        inkName: ink?.map((ele) => ele?.name).join(', '),
                        createdDate: moment(createdAt).format('DD/MM/YYYY'),
                    };
                });
                console.log('mainData', mainData);
                exportCsv(mainData);
                setIsDownloading(false);
            }
        } catch (error) {
            console.log('error', error);
            toast.error('Failed to export');
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <PageWithCard heading="Add Company">
            <ProductContainer
                modalData={modalData}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            {['admin', 'sup_admin', 'local_admin'].includes(user.role) && (
                <div className="text-right">
                    {isDownloading ? (
                        <ClipLoader />
                    ) : (
                        <PrimaryButton onClick={(e) => companyExportToCsv(e)}>
                            Export CSV
                        </PrimaryButton>
                    )}
                </div>
            )}
            <ShowDropdown
                heading={'Add Company'}
                allowedRoles={['admin', 'sup_admin', 'local_admin']}
            >
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-4"
                >
                    <FormikInputGroup
                        name={'name'}
                        label="Name"
                        formik={formik}
                        required
                    />
                    {/* machine */}
                    {user.role == 'admin' || user.role == 'sup_admin' ? (
                        <FormikSelectGroup
                            label="Dispatch Location"
                            name="dispatchLocation"
                            formik={formik}
                            options={generateOptions({
                                array: dispatchLocationData ?? [],
                                labelField: 'name',
                                valueField: '_id',
                            })}
                            required
                        />
                    ) : (
                        <FormikSelectGroup
                            label="Dispatch Location"
                            name="dispatchLocation"
                            formik={formik}
                            options={generateOptions({
                                array:
                                    dispatchLocationData.filter(
                                        (item) => item._id == user.location
                                    ) ?? [],
                                labelField: 'name',
                                valueField: '_id',
                            })}
                            required
                        />
                    )}

                    <FormikProvider value={formik}>
                        <FieldArray
                            name="machine"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2 border p-2 rounded-md">
                                        <label className="text text-base font-semibold">
                                            Add Machine
                                        </label>
                                        <div>
                                            {formik.values.machine.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative p-4 mb-2"
                                                        style={{
                                                            border: '1px solid #d6c7c7',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <FormikAsyncSelect
                                                            label="Search Machine"
                                                            formik={formik}
                                                            name={`machine.${index}`}
                                                            getOptions={async (
                                                                value
                                                            ) => {
                                                                try {
                                                                    const string =
                                                                        QueryString.stringify(
                                                                            {
                                                                                search: value,
                                                                                type: {
                                                                                    $ne: 'INK',
                                                                                },
                                                                            }
                                                                        );

                                                                    const productsResp =
                                                                        await authAxiosInstance.get(
                                                                            `/products?${string}`
                                                                        );
                                                                    const options =
                                                                        productsResp?.data?.data?.docs?.map(
                                                                            (
                                                                                ele
                                                                            ) => ({
                                                                                label: ele?.name,
                                                                                value: ele?._id,
                                                                            })
                                                                        );
                                                                    return options;
                                                                } catch (error) {
                                                                    console.log(
                                                                        error
                                                                    );
                                                                }
                                                            }}
                                                            onChange={(
                                                                selectedValue
                                                            ) => {
                                                                //check whether same id is used or not
                                                                const isSameMachineExist =
                                                                    formik?.values?.machine?.find(
                                                                        (el) =>
                                                                            el ===
                                                                            selectedValue.value
                                                                    );
                                                                if (
                                                                    isSameMachineExist
                                                                ) {
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                    return toast.error(
                                                                        'Already Selected above'
                                                                    );
                                                                }
                                                                //machineId
                                                                formik.setFieldValue(
                                                                    `machine.${index}`,
                                                                    selectedValue.value
                                                                );
                                                            }}
                                                        />
                                                        <div className="mt-6 cursor-pointer">
                                                            <DangerButton
                                                                type="button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>

                    {/* ink */}

                    <FormikProvider value={formik}>
                        <FieldArray
                            name="ink"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2 border p-2 rounded-md">
                                        <label className="text text-base font-semibold ">
                                            Add Ink
                                        </label>
                                        <div>
                                            {formik.values.ink.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative p-4 mb-2"
                                                        style={{
                                                            border: '1px solid #d6c7c7',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex flex-col gap-2">
                                                            <div className="w-full">
                                                                <FormikAsyncSelect
                                                                    label="Search Ink"
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`ink.${index}.item`}
                                                                    getOptions={async (
                                                                        value
                                                                    ) => {
                                                                        try {
                                                                            const string =
                                                                                QueryString.stringify(
                                                                                    {
                                                                                        search: value,
                                                                                        type: {
                                                                                            $ne: 'MACHINE',
                                                                                        },
                                                                                    }
                                                                                );

                                                                            const productsResp =
                                                                                await authAxiosInstance.get(
                                                                                    `/products?${string}`
                                                                                );
                                                                            const options =
                                                                                productsResp?.data?.data?.docs?.map(
                                                                                    (
                                                                                        ele
                                                                                    ) => ({
                                                                                        label: ele?.name,
                                                                                        value: ele?._id,
                                                                                    })
                                                                                );
                                                                            return options;
                                                                        } catch (error) {
                                                                            console.log(
                                                                                error
                                                                            );
                                                                        }
                                                                    }}
                                                                    onChange={(
                                                                        selectedValue
                                                                    ) => {
                                                                        //check whether same id is used or not
                                                                        const isSameInkExist =
                                                                            formik?.values?.ink?.find(
                                                                                (
                                                                                    el
                                                                                ) =>
                                                                                    el.item ===
                                                                                    selectedValue.value
                                                                            );
                                                                        if (
                                                                            isSameInkExist
                                                                        ) {
                                                                            arrayHelpers.remove(
                                                                                index
                                                                            );
                                                                            return toast.error(
                                                                                'Already Selected above'
                                                                            );
                                                                        }
                                                                        //Ink Id
                                                                        formik.setFieldValue(
                                                                            `ink.${index}.item`,
                                                                            selectedValue.value
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="flex flex-row items-center gap-2">
                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Cyan Qty."
                                                                    name={`ink.${index}.cyan`}
                                                                    type="number"
                                                                />
                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Magenta Qty."
                                                                    name={`ink.${index}.magenta`}
                                                                    type="number"
                                                                />
                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Yellow Qty."
                                                                    name={`ink.${index}.yellow`}
                                                                    type="number"
                                                                />
                                                                <FormikInputGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Black Qty."
                                                                    name={`ink.${index}.black`}
                                                                    type="number"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mt-6 cursor-pointer">
                                                            <DangerButton
                                                                type="button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>

                    {loading ? (
                        <ClipLoader />
                    ) : (
                        <FormikMultiSelect
                            formik={formik}
                            name="executive"
                            label="Executive"
                            options={
                                employees?.docs
                                    ? employees?.docs?.map((ele) => ({
                                          label: `${ele.firstName} ${ele.lastName}`,
                                          value: ele._id,
                                      }))
                                    : []
                            }
                        />
                    )}

                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
            </ShowDropdown>

            <TableWithHeadingAndSearch
                columns={cols}
                data={company?.docs?.length ? company.docs : []}
            />
            <PaginationClassic paginationDetails={company} setPage={page} />
        </PageWithCard>
    );
};

export default Company;
