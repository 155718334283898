import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { FieldArray, FormikProvider, useFormik } from 'formik';

import {
    createQuotation,
    fetchQuotation,
    getQuotation,
} from '../../app/reducers/Quotation/quotationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { authAxiosInstance } from '../../utils/axiosConfig';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';

const DuplicateQuotation = () => {
    const { id } = useParams();
    const { elementEditData, editDataLoading } = useSelector(getQuotation);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchQuotation({ _id: id }));
    }, [id]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        onSubmit: async (values) => {
            const updatedProducts = values?.products?.reduce((acc, crr) => {
                const unitPrice =
                    parseFloat(crr.price || 0) +
                    (parseFloat(crr.tax || 0) / 100) *
                        parseFloat(crr.price || 0);

                const totalPrice = unitPrice * parseFloat(crr.quantity || 0);

                acc.push({ ...crr, unitPrice, totalPrice });

                return acc;
            }, []);

            const {
                _id,
                createdAt,
                updatedAt,
                ref_no,
                status,
                approvalStatus,
                ...rest
            } = values;

            await dispatch(
                createQuotation({ ...rest, products: updatedProducts })
            );
            navigate('/quotation/view-quotation');
        },
    });
    return (
        <PageWithCard heading={`Duplicate Quotation `}>
            {editDataLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-3 w-full"
                >
                    <div className="border rounded-lg p-2">
                        <div className="flex flex-col md:flex-row gap-3 w-full  p-1">
                            <div className="w-full">
                                <FormikInputGroup
                                    formik={formik}
                                    name="consigneeName"
                                    label="Consignee Name"
                                />
                            </div>{' '}
                            <div className="w-full">
                                <FormikPhoneInputGroup
                                    formik={formik}
                                    name="consigneeContactNumber"
                                    label="Consignee Number"
                                />{' '}
                            </div>{' '}
                            <div className="w-full">
                                <FormikInputGroup
                                    formik={formik}
                                    name="consigneeAddress"
                                    label="Consignee Address"
                                />{' '}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-3 w-full  p-1">
                            <div className="w-full">
                                <FormikInputGroup
                                    formik={formik}
                                    name="consigneeState"
                                    label="Consignee State Name"
                                />
                            </div>
                            <div className="w-full">
                                <FormikInputGroup
                                    formik={formik}
                                    name="consigneeStateCode"
                                    label="Consignee State Code"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 w-full  p-1">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="customerName"
                                label="Customer Name"
                            />
                        </div>{' '}
                        <div className="w-full">
                            <FormikPhoneInputGroup
                                formik={formik}
                                name="contactNumber"
                                label="Contact Number"
                            />{' '}
                        </div>{' '}
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="address"
                                label="Address"
                            />{' '}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-3 w-full  p-1">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="state"
                                label="State Name"
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="stateCode"
                                label="State Code"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-3 w-full  p-1">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="destination"
                                label="Destination"
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="buyer_ref"
                                label="Buyer Reference"
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="other_ref"
                                label="Other Reference"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-3 w-full  p-1">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="payment_terms"
                                label="Payment Terms"
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="dispatch_through"
                                label="Dispatch Through"
                            />
                        </div>
                    </div>
                    <div>
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col gap-2 border p-2 rounded-md">
                                            <label className="text text-base font-semibold">
                                                Add Product
                                            </label>
                                            <div>
                                                {formik?.values?.products?.map(
                                                    (ele, index) => (
                                                        <div
                                                            className="relative p-4 mb-2"
                                                            style={{
                                                                border: '1px solid #d6c7c7',
                                                                borderRadius:
                                                                    '5px',
                                                            }}
                                                            key={index}
                                                        >
                                                            {formik?.values
                                                                ?.products?.[
                                                                index
                                                            ]?.productId ===
                                                                '' && (
                                                                <div className="flex flex-row gap-2 w-full items-center">
                                                                    <div className="w-full">
                                                                        <FormikAsyncSelect
                                                                            label="Select Product"
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            name={`products.${index}.productId`}
                                                                            getOptions={async (
                                                                                value
                                                                            ) => {
                                                                                if (
                                                                                    !value
                                                                                ) {
                                                                                    return [];
                                                                                }
                                                                                try {
                                                                                    const string =
                                                                                        QueryString.stringify(
                                                                                            {
                                                                                                search: value,
                                                                                            }
                                                                                        );

                                                                                    const productsResp =
                                                                                        await authAxiosInstance.get(
                                                                                            `/products?${string}`
                                                                                        );
                                                                                    const options =
                                                                                        productsResp?.data?.data?.docs?.map(
                                                                                            (
                                                                                                ele
                                                                                            ) => ({
                                                                                                label: ele?.name,
                                                                                                value: ele?._id,
                                                                                            })
                                                                                        );

                                                                                    return [
                                                                                        {
                                                                                            label: 'Other',
                                                                                            value: 'other',
                                                                                        },
                                                                                        ...options,
                                                                                    ];
                                                                                } catch (error) {
                                                                                    console.log(
                                                                                        error
                                                                                    );
                                                                                }
                                                                            }}
                                                                            onChange={(
                                                                                selectedValue
                                                                            ) => {
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.productId`,
                                                                                    selectedValue.value
                                                                                );

                                                                                if (
                                                                                    selectedValue.value ===
                                                                                    'other'
                                                                                ) {
                                                                                    formik.setFieldValue(
                                                                                        `products.${index}.productName`,
                                                                                        ''
                                                                                    );

                                                                                    formik.setFieldValue(
                                                                                        `products.${index}.tax`,
                                                                                        18
                                                                                    );
                                                                                } else {
                                                                                    formik.setFieldValue(
                                                                                        `products.${index}.productName`,
                                                                                        selectedValue.label
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        `products.${index}.tax`,
                                                                                        18
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-6">
                                                                        <DangerButton
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </DangerButton>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {formik?.values
                                                                ?.products?.[
                                                                index
                                                            ]?.productId !==
                                                                '' && (<>
                                                                <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.productName`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="Product Name"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.quantity`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="Quantity"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.price`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="Price"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.hsnCode`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="HSN Code"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.tax`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="Tax"
                                                                            type="number"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.unitPrice`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="Unit Price"
                                                                            type="number"
                                                                            value={
                                                                                formik
                                                                                    ?.values
                                                                                    ?.products?.[
                                                                                    index
                                                                                ]
                                                                                    ?.price &&
                                                                                formik
                                                                                    ?.values
                                                                                    ?.products?.[
                                                                                    index
                                                                                ]
                                                                                    ?.tax
                                                                                    ? parseFloat(
                                                                                          formik
                                                                                              .values
                                                                                              .products[
                                                                                              index
                                                                                          ]
                                                                                              .price
                                                                                      ) +
                                                                                      (parseFloat(
                                                                                          formik
                                                                                              .values
                                                                                              .products[
                                                                                              index
                                                                                          ]
                                                                                              .price
                                                                                      ) *
                                                                                          parseFloat(
                                                                                              formik
                                                                                                  .values
                                                                                                  .products[
                                                                                                  index
                                                                                              ]
                                                                                                  .tax ||
                                                                                                  0
                                                                                          )) /
                                                                                          100
                                                                                    : 0
                                                                            }
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormikInputGroup
                                                                            name={`products.${index}.totalPrice`}
                                                                            formik={
                                                                                formik
                                                                            }
                                                                            label="Total Price"
                                                                            type="number"
                                                                            value={
                                                                                formik
                                                                                    ?.values
                                                                                    ?.products?.[
                                                                                    index
                                                                                ]
                                                                                    ?.price &&
                                                                                formik
                                                                                    ?.values
                                                                                    ?.products?.[
                                                                                    index
                                                                                ]
                                                                                    ?.tax &&
                                                                                formik
                                                                                    ?.values
                                                                                    ?.products?.[
                                                                                    index
                                                                                ]
                                                                                    ?.quantity
                                                                                    ? (parseFloat(
                                                                                          formik
                                                                                              .values
                                                                                              .products[
                                                                                              index
                                                                                          ]
                                                                                              .price
                                                                                      ) +
                                                                                          (parseFloat(
                                                                                              formik
                                                                                                  .values
                                                                                                  .products[
                                                                                                  index
                                                                                              ]
                                                                                                  .price
                                                                                          ) *
                                                                                              parseFloat(
                                                                                                  formik
                                                                                                      .values
                                                                                                      .products[
                                                                                                      index
                                                                                                  ]
                                                                                                      .tax ||
                                                                                                      0
                                                                                              )) /
                                                                                              100) *
                                                                                      parseFloat(
                                                                                          formik
                                                                                              .values
                                                                                              .products[
                                                                                              index
                                                                                          ]
                                                                                              .quantity
                                                                                      )
                                                                                    : 0
                                                                            }
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                                    <div className="mt-4 cursor-pointer flex gap-2">
                                                                        <SecondaryButton
                                                                            type="button"
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.stopPropagation();
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.productId`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.productName`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.quantity`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.price`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.hsnCode`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.tax`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.unitPrice`,
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    `products.${index}.totalPrice`,
                                                                                    ''
                                                                                );
                                                                            }}
                                                                        >
                                                                            Change
                                                                        </SecondaryButton>
                                                                        <DangerButton
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </DangerButton>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        arrayHelpers.push({
                                                            productId: '',
                                                        });
                                                    }}
                                                    type="button"
                                                >
                                                    Add More
                                                </SecondaryButton>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </FormikProvider>
                    </div>

                    <div>
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="notes"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col gap-2 border p-2 rounded-md">
                                            <label className="text text-base font-semibold ">
                                                Add Notes
                                            </label>
                                            <div>
                                                {formik?.values?.notes?.map(
                                                    (ele, index) => (
                                                        <div
                                                            className="relative p-1 mb-2 flex items-center gap-2"
                                                            key={index}
                                                        >
                                                            <div className="w-full">
                                                                <FormikInputGroup
                                                                    label={`Notes Line ${index + 1}`}
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`notes.${index}`}
                                                                />
                                                            </div>
                                                            <div className="mt-6 cursor-pointer">
                                                                <DangerButton
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div className="mt-1">
                                                <SecondaryButton
                                                    onClick={() => {
                                                        arrayHelpers.push('');
                                                    }}
                                                    type="button"
                                                >
                                                    Add Notes
                                                </SecondaryButton>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </FormikProvider>
                    </div>
                    <div>
                        <PrimaryButton type="submit">Duplicate</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default DuplicateQuotation;
