import React, { useMemo } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';

const ViewSparePartPO = ({ sparePartPOData, openModal, setOpenModal }) => {
    const data = Array.isArray(sparePartPOData) ? sparePartPOData : [];
    const cols = [
        {
            Header: 'SrNo',
            Cell: ({ row }) => {
                return row.index + 1;
            },
        },
        {
            Header:"Quantity",
            accessor:"quantity"
        },
        {
            Header:"Spare Part Name",
            accessor:"sparePartId.name"
        },
     
       
    ];
    const columnsMemo = useMemo(() => cols ?? [], [cols]);

    return (
        <ModalBasic modalOpen={openModal} setModalOpen={setOpenModal}>
            <div className="p-4">
                <TableWithHeadingAndSearch
                    data={data}
                    columns={columnsMemo}
                    heading={'Spare Part'}
                />
            </div>
        </ModalBasic>
    );
};

export default ViewSparePartPO;
