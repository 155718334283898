import CompanyList from '../pages/Approval/CompanyList';
import CustomerList from '../pages/Approval/CustomerList';
import ViewApproval from '../pages/Approval/ViewApproval';
import AddComplaint from '../pages/Complaints/AddComplaint';
import ViewComplaint from '../pages/Complaints/ViewComplaint';
import Dashboard from '../pages/Dashboard';
import AddDispatchLocation from '../pages/DispatchLocation/AddDispatchLocation';
import ViewDispatchLocation from '../pages/DispatchLocation/ViewLocation';
import Company from '../pages/Master/Company';
import EditCompany from '../pages/Master/EditCompany';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import AddProduct from '../pages/Products/AddProduct';
import MachineSparePartsTable from '../pages/Products/MachineSparePartsTable';
import SpareParts from '../pages/Products/SpareParts';
import SparePartsMapping from '../pages/Products/SparePartsMapping';
import CreatePurchaseOrder from '../pages/PurchaseOrder/CreatePurchaseOrder';
import CreateSparePartPO from '../pages/PurchaseOrder/CreateSparePartPO';
import ViewPurchaseOrder from '../pages/PurchaseOrder/ViewPurchaseOrder';
import ViewSparePartPO from '../pages/PurchaseOrder/ViewSparePartPO';
import AddQuotation from '../pages/Quotation/AddQuotation';
import DuplicateQuotation from '../pages/Quotation/DuplicateQuotation';
import EditQuotation from '../pages/Quotation/EditQuotation';
import ViewQuotation from '../pages/Quotation/ViewQuotation';
import AddCustomer from '../pages/Users/AddCustomer';
import AddEmployee from '../pages/Users/AddEmployee';
import EditCustomer from '../pages/Users/EditCustomer';
import EditEmployee from '../pages/Users/EditEmployee';
import EditEmployeePage from '../pages/Users/EditEmployeePage';
import ViewCustomer from '../pages/Users/ViewCustomer';
import ViewEmployees from '../pages/Users/ViewEmployees';
import GeneralBulkUpload from '../pages/generalBulkUpload/GeneralBulkUpload';

export const routes = [
    {
        route: '/',
        element: <Dashboard />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
    },
    {
        route: '/users/add-employee',
        element: <AddEmployee />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: '/users/edit-employee',
        element: <EditEmployee />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: '/users/editEmployee/:id',
        element: <EditEmployeePage />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: '/users/view-employee',
        element: <ViewEmployees />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: '/users/add-customer',
        element: <AddCustomer />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin'],
    },
    {
        route: '/users/view-customer',
        element: <ViewCustomer />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
    },
    {
        route: '/users/edit-customer/:id',
        element: <EditCustomer />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
    },
    {
        route: '/product/add-product',
        element: <AddProduct />,
        allowedRoles: ['admin', 'sup_admin'],
    },

    {
        route: '/mediaLibrary',
        element: <MediaLibrary />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: '/mediaLibrary/*',
        element: <MediaLibrary />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: 'generalBulkUpload/*',
        element: <GeneralBulkUpload />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    //complaints
    {
        route: '/complaint/add-complaint',
        element: <AddComplaint />,
        allowedRoles: ['admin', 'sup_admin','local_admin', 'executive'],
    },
    {
        route: '/complaint/view-complaint',
        element: <ViewComplaint />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
    },
    //purchaseOrder
    {
        route: '/purchaseOrder/add-purchaseOrder',
        element: <CreatePurchaseOrder />,
        allowedRoles: ['admin', 'sup_admin','local_admin','executive'],
    },
    {
        route: '/purchaseOrder/view-SparePartPO/:poStatus',
        element: <ViewSparePartPO />,
        allowedRoles: ['admin', 'sup_admin','local_admin','executive'],
    },
    {
        route: '/purchaseOrder/view-purchaseOrder/:poStatus',
        element: <ViewPurchaseOrder />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
    },
    {
        route: '/purchaseOrder/addSparePartPurchaseOrder',
        element: <CreateSparePartPO />,
        allowedRoles: ['admin', 'sup_admin','local_admin','executive'],
    },
    // {
    //     route : '/purchaseOrder/viewSparePartPurchaseOrder',
    //     element : <ViewSparePartPO />,
    //     allowedRoles : ['admin','sup_admin'],
    // },
    //Dispatch Location
    {
        route: '/dispatchLocation/add',
        element: <AddDispatchLocation />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        route: '/dispatchLocation/view',
        element: <ViewDispatchLocation />,
        allowedRoles: ['admin', 'sup_admin','local_admin'],
    },
    {
        route: '/master/company',
        element: <Company />,
        allowedRoles: ['admin','local_admin', 'sup_admin'],
    },
    {
        route: '/master/company/edit/:id',
        element: <EditCompany />,
        allowedRoles: ['admin','local_admin', 'sup_admin'],
    },
    {
        route: '/product/spareParts',
        element: <SpareParts />,
        allowedRoles: ['sup_admin','admin'],
    },
    {
        route: '/product/sparePartsMapping',
        element: <SparePartsMapping />,
        allowedRoles: ['sup_admin','admin'],
    },
    {
        route: '/product/machineSpareParts/:id',
        element: <MachineSparePartsTable />,
        allowedRoles: ['sup_admin','admin'],
    },
    // {
    //     route: '/approval/viewApprovals',
    //     element: <ViewApproval />,
    //     allowedRoles: ['sup_admin','admin', 'local_admin'],
    // },
    {
        route : '/approval/customerList',
        element : <CustomerList />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin'],
    },
    {
        route : '/approval/companyList',
        element : <CompanyList />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin'],
    },
    {
        route: '/quotation/add-quotation',
        element: <AddQuotation />,
        allowedRoles: ['sup_admin','admin', 'local_admin','executive'],
    },
    {
        route: '/quotation/view-quotation',
        element: <ViewQuotation />,
        allowedRoles: ['sup_admin','admin','local_admin','executive'],
    },
    {
        route: '/quotation/edit-quotation/:id',
        element: <EditQuotation />,
        allowedRoles: ['sup_admin','admin','local_admin','executive'],
    },
    {
        route: '/quotation/duplicate-quotation/:id',
        element: <DuplicateQuotation />,
        allowedRoles: ['sup_admin','admin','local_admin','executive'],
    },
];
