import {
    Archive,
    BookOpen,
    Briefcase,
    Monitor,
    Package,
    ThumbsUp,
    Twitch,
    User,
} from 'react-feather';

export const LINKS = [
    {
        type: 'single',
        link: '/',
        label: 'Dashboard',
        Icon: <Monitor />,
        allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
    },
    {
        links: [
            {
                label: 'Add Employee',
                link: '/users/add-employee',
                allowedRoles: ['admin', 'sup_admin'],
            },
            {
                label: 'View Employee',
                link: '/users/view-employee',
                allowedRoles: ['admin', 'sup_admin'],
            },
            {
                label: 'Edit Employee',
                link: '/users/edit-employee',
                allowedRoles: ['admin', 'sup_admin'],
            },
            {
                label: 'Add Customer',
                link: '/users/add-customer',
                allowedRoles: ['admin', 'sup_admin', 'local_admin'],
            },
            {
                label: 'View Customer',
                link: '/users/view-customer',
                allowedRoles: ['admin', 'sup_admin', 'local_admin'],
            },
        ],
        heading: 'Users',
        Icon: <User />,
        type: 'group',
    },
    {
        links: [
            {
                label: 'Company',
                link: '/master/company',
                allowedRoles: ['admin','local_admin', 'sup_admin'],
            },
        ],
        heading: 'Master',
        Icon: <User />,
        type: 'group',
    },

    //complaints
    {
        links: [
            {
                label: 'Add Complaint',
                link: '/complaint/add-complaint',
                allowedRoles: ['admin', 'sup_admin','local_admin', 'executive'],
            },
            {
                label: 'View Complaint',
                link: '/complaint/view-complaint',
                allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
            },
        ],
        heading: 'Complaints',
        Icon: <Twitch />,
        type: 'group',
    },

    //Approval
    // {
    //     type: 'single',
    //     link: '/approval/viewApprovals',
    //     label: 'Approval',
    //     Icon: <ThumbsUp />,
    //     allowedRoles: ['sup_admin','admin'],
    // },
    {   links:[
        {
            label : 'Customers List',
            link : '/approval/customerList',
            allowedRoles: ['admin', 'sup_admin', 'local_admin'],
        },
        {
            label : 'Company List',
            link : '/approval/companyList',
            allowedRoles: ['admin', 'sup_admin', 'local_admin'],
        },
        // {
        //     label: 'Approval',
        //     link: '/approval/viewApprovals',
        //     allowedRoles: ['sup_admin','admin', 'local_admin'],
        // },
    ],
        heading : 'Approval',
        Icon : <ThumbsUp />,
        type : 'group'
    },

    //purchaseOrder

    {
        links: [
            {
                label: 'Create PurchaseOrder',
                link: '/purchaseOrder/add-purchaseOrder',
                allowedRoles: ['admin', 'sup_admin','local_admin','executive'],
            },
            {
                label: 'View PurchaseOrder',
                link: '/purchaseOrder/view-purchaseOrder/open',
                allowedRoles: ['admin', 'sup_admin', 'local_admin', 'executive'],
            },
            {
                label: 'Create SparePart PurchaseOrder',
                link: '/purchaseOrder/addSparePartPurchaseOrder',
                allowedRoles: ['admin', 'sup_admin','local_admin','executive'],
            },
            {
                label: 'View Spare Part Purchase Orders',
                link: '/purchaseOrder/view-SparePartPO/open',
                allowedRoles: ['admin', 'sup_admin','local_admin','executive'],
            },
        ],
        heading: 'Purchase Order',
        Icon: <Package />,
        type: 'group',
    },

    //product
    // {
    //     type: 'single',
    //     link: '/add-product',
    //     label: 'Products',
    //     Icon: <Archive />,
    //     allowedRoles: ['admin','sup_admin'],
    // },
    {
        links: [
            {
                label: 'Add Products',
                link: '/product/add-product',
                allowedRoles: ['admin', 'sup_admin'],
            },
            {
                label: 'Add Spare Parts',
                link: '/product/spareParts',
                allowedRoles: ['sup_admin','admin'],
            },
            {
                label: 'Spare Parts Mapping',
                link: '/product/sparePartsMapping',
                allowedRoles: ['sup_admin','admin'],
            },
        ],
        heading: 'Products',
        Icon: <Archive />,
        type: 'group',
    },

    {
        links: [
            {
                label: 'Add Quotation',
                link: '/quotation/add-quotation',
                allowedRoles: ['sup_admin','admin','local_admin','executive'],
            },
            {
                label: 'View Quotation',
                link: '/quotation/view-quotation',
                allowedRoles: ['sup_admin','admin','local_admin','executive'],
            },
        ],
        heading: 'Quotation',
        Icon: <BookOpen />,
        type: 'group',
    },

    ,
    //Dispatch Location
    {
        links: [
            {
                label: 'Add Dispatch Location',
                link: '/dispatchLocation/add',
                allowedRoles: ['admin', 'sup_admin'],
            },
            {
                label: 'View Dispatch Location',
                link: '/dispatchLocation/view',
                allowedRoles: ['admin', 'sup_admin','local_admin'],
            },
        ],
        heading: 'Dispatch Location',
        Icon: <User />,
        type: 'group',
    },
    {
        type: 'single',
        link: '/generalBulkUpload',
        label: 'General Bulk Upload',
        Icon: <Briefcase />,
        allowedRoles: ['admin', 'sup_admin'],
    },
    {
        type: 'single',
        link: '/mediaLibrary',
        label: 'Media Library',
        Icon: <Briefcase />,
        allowedRoles: ['admin', 'sup_admin'],
    },
];
