import React from 'react';
import { ClipLoader } from 'react-spinners';
import { useTable } from 'react-table';

const SearchComponent = ({ searchFunction }) => {
    return (
        <div className="max-w-xl mb-5">
            <div className="relative">
                <input
                    className="form-input w-full pl-9 py-3 focus:border-slate-300"
                    type="search"
                    placeholder="Search…"
                    onChange={(e) => {
                        searchFunction(e.target.value);
                    }}
                />
                <button
                    className="absolute inset-0 right-auto group"
                    aria-label="Search"
                >
                    <svg
                        className="w-4 h-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500 ml-3 mr-2"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                        <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

const TableWithHeadingAndGlobalSearch = ({
    data,
    columns,
    tableClass,
    heading,
    initialState,
    searchFunction,
    loading,
}) => {
    const tableInstance = useTable({ data, columns, initialState });
    const {
        getTableProps,
        getTableBodyProps,
        rows,
        prepareRow,
        headerGroups,
        state,
    } = tableInstance;

    return (
        <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
            <header className="px-5 py-4">
                {typeof heading === 'string' ? (
                    <h2 className="font-semibold text-slate-800">{heading}</h2>
                ) : (
                    <>{heading}</>
                )}
            </header>
            <div>
                {/* Table */}
                <div className="overflow-x-auto ">
                    <SearchComponent searchFunction={searchFunction} />
                    {loading ? (
                        <ClipLoader />
                    ) : (
                        <div className={`${tableClass}`}>
                            <table
                                className="table-auto w-full "
                                {...getTableProps()}
                            >
                                {/* Table header */}
                                <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                            
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        <div className="font-semibold text-left">
                                                            {column.render(
                                                                'Header'
                                                            )}
                                                        </div>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                {/* Table body */}
                                <tbody
                                    className="text-sm divide-y divide-slate-200 "
                                    {...getTableBodyProps()}
                                >
                                    {rows.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap "
                                                            {...cell.getCellProps()}
                                                        >
                                                            <div className="text-left">
                                                                {cell.render(
                                                                    'Cell'
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TableWithHeadingAndGlobalSearch;
